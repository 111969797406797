<template>
  <el-container class="login_container">
    <el-form
      ref="form"
      class="login_content"
      autocomplete="off"
      @keyup.enter="doLogin"
    >
      <!-- <el-image
        style="width: 300px;"
        :src="require('@/assets/images/logo.png')"
        class='logo'
        ></el-image> -->
      <div class="login_main_title">
        <p>报名系统</p>
      </div>
      <div class="login_input_main">
        <div class="login_input_form">
          <div class="login_block_row">
            <el-input
              placeholder="请输入账号"
              v-model.trim="data.phone"
              class="login_block_input"
              required="required"
              autocapitalize="off"
            >
              <template class="login_block_input" #prepend>手机号:</template>
            </el-input>
          </div>
          <div class="login_block_row">
            <el-input
              placeholder="请输入验证码"
              v-model="data.code"
              class="login_block_input"
              autocomplete="new-password"
              required="required"
              autocapitalize="off"
            >
              <template class="login_block_input" @click="sendCode" #prepend
                >验证码:</template
              >
              <template class="login_block_input" #suffix>
                <span @click="sendCode">{{
                  timer.text || "获取验证码"
                }}</span></template
              >
            </el-input>
          </div>
          <div class="login_block_row">
            <el-button type="primary" class="login_button" @click="doLogin"
              >登录</el-button
            >
          </div>
        </div>
      </div>
    </el-form>
    <top class="top" :colorData="colorData"></top>
  </el-container>
</template>

<script>
import top from "./top.vue";
export default {
  name: "login",
  components: {
    top,
  },
  data() {
    return {
      colorData: "#fff",
      data: {
        phone: "",
        code: "",
      },
      codeLoading: false,
      loading: false,
      timer: {
        time: null,
        maxCount: 60000,
        startTime: 0,
        text: "",
      },
    };
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "报名系统";
    let data = this.$tools.getStorage("loginReportData", localStorage) || {};
    if (data) this.data = data;
    this.timer.startTime = this.$tools.getStorage("downStartTime");
    this.checkTime();
  },
  methods: {
    sendCode() {
      if (this.codeLoading || this.timer.time) return;
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号码" });
        return false;
      }
      if (!/^1\d{10}$/g.test(this.data.phone)) {
        this.$tips.error({ text: "手机号码不正确" });
        return false;
      }
      this.codeLoading = true;
      this.$axios
        .get(`${this.$urls.hospital.code}?phone=${this.data.phone}`)
        .then(() => {
          setTimeout(() => {
            this.codeLoading = false;
          }, 1000);
          this.timer.startTime = Date.now();
          this.checkTime();
          this.$tips.success({ text: "发送成功" });
        })
        .catch(() => {
          setTimeout(() => {
            this.codeLoading = false;
          }, 1000);
        });
    },
    checkTime() {
      if (this.timer.time) {
        clearTimeout(this.timer.time);
        this.timer.time = null;
      }
      let curTime = Date.now();
      if (this.timer.startTime + this.timer.maxCount > curTime) {
        this.timer.text = `${Math.floor(
          (this.timer.maxCount - (curTime - this.timer.startTime)) / 1000
        )}s`;
        this.$tools.setStorage("downStartTime", this.timer.startTime);
        this.timer.time = setTimeout(() => {
          this.checkTime();
        }, 1000);
      } else {
        this.timer.startTime = 0;
        (this.timer.text = ""), (this.codeLoading = false);
        this.$tools.setStorage("downStartTime", this.timer.startTime);
      }
    },
    verityData() {
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号码" });
        return false;
      }
      if (!/^1\d{10}$/g.test(this.data.phone)) {
        this.$tips.error({ text: "手机号码不正确" });
        return false;
      }
      if (!this.data.code) {
        this.$tips.error({ text: "请输入验证码" });
        return false;
      }
      return true;
    },
    doLogin() {
      if (!this.verityData() || this.loading) return;
      this.loading = true;

      this.$tools.setStorage("loginReportData", this.data, localStorage);
      this.$axios
        .post(this.$urls.hospital.login, this.data)
        .then((res) => {
          this.$store.dispatch("setItem", { userReportInfo: res });
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.$router.push({
            path: "/reportList",
          });
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.login_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222;
}
input:-webkit-autofill {
  background-color: #333 !important;
}
.login_content {
  height: auto;
}
.login_input_main {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login_input_form {
  width: 300px;
}
.login_block_row {
  height: 50px;
}
.login_block_input > :nth-child(n) {
  background: #333 !important;
  border: 0;
  color: #fff;
}
.login_button {
  width: 100%;
}
.login_block_row .el-input__suffix {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
}

.login_block_row .el-input__suffix:hover {
  cursor: pointer;
}
.login_main_title {
  font-size: 45px;
  line-height: 60px;
  color: #fff;
  font-weight: 700;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px 0;
  text-align: center;
}
.login_container .top {
  position: fixed;
  bottom: 0.2rem;
}
.login_container .el-input__inner,
.login_container .el-input-group__prepend {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.login_container .el-input__suffix {
  z-index: 999;
}

.login_container .el-input__wrapper.is-focus,
.login_container .el-input__wrapper {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.login_container .el-input__inner {
  color: #fff !important;
}
</style>
